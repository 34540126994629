import { Component, Vue, Watch } from 'vue-property-decorator';

import { SidemenuItem } from '@/components/sidemenu/Sidemenu';

import { getStatusLabel } from '@/support/ReportStatus';

@Component<Schadeafhandeling>({})
export default class Schadeafhandeling extends Vue {
  public $pageTitle = 'Schadeafhandeling';

  protected isLoading = true;

  protected items: SidemenuItem[] = [
    {
      title: 'Dashboard',
      link: '/schadeafhandeling',
      icon: {
        key: 'dashboard',
      },
    },
    {
      title: 'Mededelingen',
      link: '/schadeafhandeling/mededelingen',
      icon: {
        key: 'notification_important',
      },
    },
  ];

  protected naderAdvies: SidemenuItem[] = [
    {
      title: getStatusLabel('zienswijze_werkvoorbereiding_rejected'),
      link: '/schadeafhandeling/reports?status=zienswijze_werkvoorbereiding_rejected',
      icon: {
        key: 'reply_all',
      },
    },
    {
      title: getStatusLabel('zienswijze_approved'),
      link: '/schadeafhandeling/reports?status=zienswijze_approved',
      icon: {
        key: 'done_all',
      },
    },
    {
      title: getStatusLabel('zienswijze_corrected'),
      link: '/schadeafhandeling/reports?status=zienswijze_corrected',
      icon: {
        key: 'add_comment',
      },
    },
    {
      title: getStatusLabel('zienswijze_closed'),
      link: '/schadeafhandeling/reports?status=zienswijze_closed',
      icon: {
        key: 'move_to_inbox',
      },
    },
  ];

  protected itemsException: SidemenuItem[] = [
    {
      title: getStatusLabel('zienswijze_onhold'),
      link: '/schadeafhandeling/reports?status=zienswijze_onhold',
      icon: {
        key: 'remove_circle_outline',
      },
    },
  ];

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Schadeafhandeling' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
